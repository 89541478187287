
import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import HacizForm from "@/components/forms/haciz/HacizForm.vue";
import {HacizTalebiEntity} from "@/entity/Haciz/HacizTalebiEntity";
import {HacizEntity} from "@/entity/Haciz/HacizEntity";
import {BorcluEntity} from "@/entity/BorcluEntity";
import SatisBilgiForm from "@/components/forms/satis/SatisBilgiForm.vue";
import {SatisBilgiEntity} from "@/entity/SatisBilgiEntity";
import {HacizTalepKodu} from "@/enum/haciz/HacizTalepKodu";

@Component({
  computed: {
    SatisBilgiEntity() {
      return SatisBilgiEntity
    }
  },
  components: {SatisBilgiForm, HacizForm, FormDialog, DeleteDialog, FormWrapper}
})
export default class HacizTalepForm extends Mixins(SimpleInputMixin) {
  @Prop() borclu!: BorcluEntity;
  @Ref('form') form!: FormWrapper;

  tab = 0;
  error = false;
  localValue!: HacizTalebiEntity;
  HacizTalepKodu = HacizTalepKodu

  headers = [
    {text: 'Haciz Türü', value: 'haciz_turu.isim'},
    {text: 'Açıklama', value: 'aciklama'},
    {text: 'Masraf', value: 'masraf'},
    {text: 'İşlemler', value: 'actions', align: 'center', width: 140}
  ];

  get defaultHaciz() {
    let hacizObj = new HacizEntity();
    return hacizObj;
  }

  get toplamMasraf() {
    let tutar = 0;
    this.localValue.hacizler.forEach((haciz: HacizEntity | any) => {
      if (haciz.uyap_data) {
        if (haciz.uyap_data.talepKodu == 4)
          tutar += haciz.uyap_data.talepMasrafi * haciz.uyap_data.bankaList.length;
        else
          tutar += haciz.uyap_data?.talepMasrafi;
      }
    });
    this.localValue.talep_tutari = tutar;
    return tutar;
  }

  onAdd(v: any) {
    this.localValue.hacizler.push(v);
  }

  onUpdate(item: any, index: number) {
    let newArray = [...this.localValue.hacizler];
    newArray[index] = item;
    this.localValue.hacizler = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.hacizler.splice(index, 1);
  }

  load() {
    this.$emit('load');
  }

  async save() {
    this.form.onFly = true;
    // boş haciz talebi yollamamak için kontrol sağlanacak
    /*
        uyapa bağlıyken
        YENİ haciz
        Kaydet: Bilgileri direk dbye kaydet
        Kaydet ve Uyap'a Gönder: Bilgileri direk dbye kaydet, Uyap'a gönder, evrak idyi al ve dbdeki ilgili alanı güncelle, evrağı indir parse et ve ekstralara ekle
     */
    if (this.tab == 0) {
      if (this.localValue.hacizler.length == 0) {
        this.error = true;
        this.form.onFly = false;
        return;
      }
      this.error = false;
      if (this.localValue.id) {
        await this.$http.put('/api/v1/haciz-talep/' + this.localValue.id, this.localValue).catch(() => this.form.onFly = false);
        for (let haciz of this.localValue.hacizler) {
          if (haciz.id) {
            await this.$http.post('/api/v1/haciz-talep/' + this.localValue.id + '/haciz/' + haciz.id, haciz).catch(() => this.form.onFly = false);
          } else {
            haciz.haciz_talep_id = this.localValue.id;
            await this.$http.post('/api/v1/haciz-talep/' + this.localValue.id + '/haciz', haciz).catch(() => this.form.onFly = false);
          }
        }
        this.form.onFly = false;
        this.$emit('onSuccess');
      } else {
        this.localValue.borclu_id = this.borclu.id;
        let response = await this.$http.post('/api/v1/haciz-talep', this.localValue);
        for (let haciz of this.localValue.hacizler) {
          haciz.haciz_talep_id = response.data.id;
          await this.$http.post('/api/v1/haciz-talep/' + response.data.id + '/haciz', haciz).catch(() => this.form.onFly = false);
        }
        this.form.onFly = false;
        this.$emit('onSuccess');
      }
    } else {
      // MEVCUT haciz
      // Hacizi seçtir, evrakı parse et ve ekstralara ekle
      // uyaptan parse et aktar
    }
  }
}
