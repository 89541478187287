export enum HacizTalepKodu {
    arac_haczi = 0,
    maas_haczi = 1,
    gayrimenkul_haczi = 2,
    posta_ceki_haczi = 3,
    banka_haczi = 4,
    seksen_dokuz_bir = 5,
    sgk_haczi = 6,
    alacakli_dosya = 7,
    tasinir_haczi_talebi = 23,
    tasinir_haczi_talimati = 24,
    seksen_dokuz_iki = 43,
    seksen_dokuz_uc = 44
}

export enum HacizTalepKoduLabel {
    "Araç Haczi Talebi",
    "Maaş Haczi Talebi",
    "Gayrimenkul Haczi Talebi",
    "Posta Çeki Haczi Talebi",
    "Banka Haczi Talebi",
    "89-1 İhbarname Talebi",
    "SGK Mossip Haczi Talebi",
    "Alacaklı Olduğu Dosya",
    "Taşınır Haciz Talebi" = 23,
    "Taşınır Haciz Talimatı Talebi",
    "89-2 İhbarname Talebi" = 43,
    "89-3 İhbarname Talebi",




}